/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import PropTypes from 'prop-types';
import { InstagramPost } from 'dw-contentful-components';

const RichText = ({ content, options }) => {
  if (!content) {
    return null;
  }

  const { references } = content;

  const defaultOptions = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <i>{text}</i>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    },

    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [BLOCKS.OL_LIST]: (node, children) => {
        return (
          <ol>
            {children.map((child) => {
              return child;
            })}
          </ol>
        );
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <ul>
            {children.map((child) => {
              return child;
            })}
          </ul>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) => {
        const {
          target: { contentful_id: assetID },
        } = data;

        // find linked asset in references
        const matchingReference = references.filter(
          ({ contentful_id: thisID }) => assetID === thisID,
        )[0];

        if (!references || !matchingReference) {
          return null;
        }

        const {
          internal: { type },
        } = matchingReference;

        switch (type) {
          case 'ContentfulInstagramPost':
            return <InstagramPost postId={matchingReference.instagramPostId} />;
          default:
            return null;
        }
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const {
          data: { uri },
        } = node;
        return <a href={uri}>{children}</a>;
      },
    },
  };

  return <>{content && renderRichText(content, options || defaultOptions)}</>;
};

const props = {
  content: PropTypes.object.isRequired,
  options: PropTypes.object,
};

const defaultProps = {
  options: RichText.defaultOptions,
};

RichText.propTypes = props;
RichText.defaultProps = defaultProps;

export { RichText };
