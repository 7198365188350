// Back to home button which will typically appear in a reference card section when it is paginated
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronButtonWithText } from 'dw-contentful-components';
import { generateLink } from 'dw-contentful-components/src/util/generateLink';
import '../../styles/BackToHome.scss';

const propTypes = {
  brand: PropTypes.string,
};

const defaultProps = {
  brand: 'law',
};

/**
 * Back To Home is a chevon button with text that returns a user to the homepage
 * @param {string} brand The brand used for local navigation to return to homepage
 */
const BackToHome = ({ brand }) => {
  const [homepageUrl, setHomepageUrl] = useState('');

  useEffect(() => {
    const {
      location: { origin },
    } = window;

    const path =
      process.env.NODE_ENV === 'production'
        ? generateLink({
            jspFileName: 'home',
          })
        : `${origin}/home/${brand}`;

    setHomepageUrl(path);
  }, []);

  const buttonHandler = () => {
    window.location.href = homepageUrl;
  };

  return (
    <ChevronButtonWithText
      chevronDirection="left"
      text="Back to Content Hub"
      onClick={buttonHandler}
      url={homepageUrl}
    />
  );
};

BackToHome.propTypes = propTypes;
BackToHome.defaultProps = defaultProps;

export { BackToHome };
