/**
 * Reference card section is the container for all reference cards, which can link to different pages
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { BackToHome } from '../BackToHome';
import { ArticleReferenceCardWrapper } from '../ArticleReferenceCardWrapper';
import { ButtonWithContentWrapper } from '../ButtonWithContentWrapper';
import { ReferenceCardWrapper } from '../ReferenceCardWrapper';

import standardiseContentfulId from '../../util/standardiseContentfulId';
import '../../styles/index.scss';

const propTypes = {
  brand: PropTypes.string,
  data: PropTypes.object,
  backButton: PropTypes.boolean,
};

const defaultProps = {
  brand: 'law',
  data: {},
  backButton: false,
};

/**
 * Reference Card Section
 * @param {string} brand The brand to style this component for
 * @param {object} data Contains the data from contentful such as the reference cards themselves
 * @param {boolean} backButton Whether the back button is displayed inline with the title
 */
const ReferenceCardSection = ({ brand, data, backButton }) => {
  const { contentfulId, title: heading, referenceCards } = data;

  const renderCardType = (refCard) => {
    const cardType = refCard?.__typename;

    switch (cardType) {
      case 'ContentfulButtonWithContent': {
        return (
          <ButtonWithContentWrapper
            brand={brand}
            data={{ ...refCard, hasShadow: false, card: true }}
          />
        );
      }
      case 'ContentfulArticlePage': {
        return <ArticleReferenceCardWrapper article={refCard} brand={brand} />;
      }
      case 'ContentfulReferenceCard': {
        return <ReferenceCardWrapper brand={brand} card={refCard} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <section
      data-testid="reference-card-section"
      className={`${brand} bg-shadow reference-card-section`}
      id={standardiseContentfulId(contentfulId)}
    >
      <Container className={`pt-5 ${brand}`}>
        {heading && (
          <Row
            data-testid="reference-card-section-heading"
            className="mx-0 mb-5"
          >
            <Col className="p-0 reference-card-section-heading">
              <h2>{heading}</h2>
              {backButton && <BackToHome brand={brand} />}
            </Col>
          </Row>
        )}
        <Row className="ml-md-0">
          {referenceCards?.map((card) => (
            <Col
              className="pb-4 pl-md-0 reference-card-item"
              xs={12}
              md={6}
              key={card.pageIdentifier}
            >
              {renderCardType(card)}
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

ReferenceCardSection.propTypes = propTypes;
ReferenceCardSection.defaultProps = defaultProps;

export { ReferenceCardSection };
