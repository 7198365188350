/**
 * Hero Banner Wrapper wraps the component library hero banner so it can utilised rich text elements
 */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { HeroBanner } from 'dw-contentful-components';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { RichText } from '../RichText';
import standardiseContentfulId from '../../util/standardiseContentfulId';

const props = {
  brand: PropTypes.string.isRequired,
  heroBanner: PropTypes.shape({
    contentfulId: PropTypes.string,
    contentTitle: PropTypes.object,
    contentText: PropTypes.object,
    image: PropTypes.object,
  }).isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: '',
};

const headingRichTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (richText) => <strong>{richText}</strong>,
    [MARKS.ITALIC]: (richText) => <i>{richText}</i>,
    [MARKS.UNDERLINE]: (richText) => <u>{richText}</u>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <h2>{children}</h2>,
  },
};

const headingVariant = {
  "1": 'h1',
  "2": 'h2',
}

/**
 * Hero Banner Wrapper
 * @param {string} brand The brand to style this component for
 * @param {object} heroBanner The data from contentful for the hero banner
 * @param {string} title Used to override the default conttent on List pages
 * @param {number} variant Used to override the default heading tag
 */
const HeroBannerWrapper = ({ brand, heroBanner, title, variant = 2 }) => {
  const [headingOptions, setHeadingOptions] = useState({...headingRichTextOptions});
  const {
    contentfulId,
    contentTitle,
    contentText,
    image: mediaData,
  } = heroBanner;
  const titleOverride = title && title.length > 1;
  const HeadingTag = useMemo(() => {
    return headingVariant[`${variant}`] || headingVariant["2"];
  }, [variant]);

  // Override H1 if variant = 1 for reach text box
  useEffect(() => {
    if (variant == 1 && !titleOverride) {
      const renderNode = {
        [BLOCKS.PARAGRAPH]: (node, children) => <h1>{children}</h1>,
      }
      setHeadingOptions({ ...headingRichTextOptions, renderNode });
    } else {

      setHeadingOptions({ ...headingRichTextOptions });
    }
  }, [variant, titleOverride])

  const heroTitle = titleOverride ? (
    <HeadingTag>{title}</HeadingTag>
  ) : (
    <RichText content={contentTitle} options={headingOptions} />
  );

  const heroText = <RichText content={contentText} />;

  const {
    accessibilityText: alt,
    asset: { fluid },
  } = mediaData;

  return (
    <div
      data-testid="hero-banner-wrapper"
      id={standardiseContentfulId(contentfulId)}
    >
      <HeroBanner
        brand={brand}
        bannerTitle={heroTitle}
        bannerText={!titleOverride && heroText}
        imageAlt={alt}
        imageFluid={fluid}
        variant={variant}
      />
    </div>
  );
};

HeroBannerWrapper.propTypes = props;
HeroBannerWrapper.defaultProps = defaultProps;

export { HeroBannerWrapper };
