import { generateLink } from "dw-contentful-components/src/util/generateLink";

const getTextFromContentArray = (data = [], text = "") => {
    if (Array.isArray(data)) {
        data.map(i => {
            if (Array.isArray(i.content)) {
                text += getTextFromContentArray(i.content, text);
                return;
            } else if (i.value && typeof i.value == "string") {
                text += i.value;
            }
        });
    }

    return text;
}

const getTextFromSections = (sections = []) => {
    let text = "";
    if (!Array.isArray(sections)) {
        return text;
    }

    sections.map(item => {
        try {
            const nodeItem = JSON.stringify(item.content.raw);
            text += getTextFromContentArray(nodeItem?.content, "");
        } catch (e) {
            console.log("> Invalid data");
        }
    });

    return text;
}

export const getArticleSchemaMarkup = ({
    schemaType,
    pageIdentifier,
    postedOn,
    heroBanner = {},
    entryTitle,
    sections = [],
    location = {}
}) => {
    if (!entryTitle || !postedOn || !schemaType) {
        return undefined;
    }

    const {
        image = {}
    } = heroBanner;

    const wholeText = getTextFromSections(sections);
    const thumbnailUrl = image?.asset?.gatsbyImageData?.images?.src || "";
    const datePublished = new Date(postedOn).toISOString();
    const pageOrigin = location && location.origin ? location.origin : '';
    const pageUrl = location && location.href ? location.href : '';

    return {
        "@context": "https://schema.org",
        "@type": schemaType,
        "@id": pageUrl,
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": pageUrl
        },
        "headline": entryTitle,
        "datePublished": datePublished,
        "dateModified": datePublished,
        "wordCount": wholeText.length,
        "image": thumbnailUrl,
        "thumbnailUrl": thumbnailUrl,
        "articleSection": entryTitle,
        "isPartOf": [
            {
                "@id": pageUrl,
                "url": pageOrigin,
                "name": entryTitle,
                "description": entryTitle,
                "datePublished": datePublished,
                "dateModified": datePublished
            }
        ]
    }
}

const getIntro = (stringObject) => {

    try {
        const data = JSON.parse(stringObject);
        let text = "";
        if (data && data.content) {
            text += getTextFromContentArray(data.content);
        }
        return text;
    } catch (e) {
        return "";
    }
}

export const getCollectionSchemaMarkup = ({
    categoryTitle,
    location = {},
    intro = {}
}) => {
    if (!categoryTitle) {
        return undefined;
    }

    const pageOrigin = location && location.origin ? location.origin : '';
    const pageUrl = location && location.href ? location.href : '';
    const description = getIntro(intro.raw);

    const schemaObject = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "Organization",
                "@id": pageUrl,
                "name": categoryTitle,
                "url": pageOrigin,
            },
            {
                "@type": "WebSite",
                "@id": pageUrl,
                "url": pageOrigin,
                "name": categoryTitle,
                "description": description,
                "publisher": {
                    "@id": pageUrl
                },
                "inLanguage": "en-GB"
            },
            {
                "@type": "CollectionPage",
                "@id": pageUrl,
                "url": pageUrl,
                "name": categoryTitle,
                "description": description,
                "isPartOf": {
                    "@id": pageUrl
                },
                "inLanguage": "en-GB",
                "potentialAction": [
                    {
                        "@type": "ReadAction",
                        "target": [
                            pageUrl
                        ]
                    }
                ]
            }
        ]
    }

    return schemaObject;
}

// Schema build for home page/ collection page
// UXE-11005 - SEO
const getItemListElement = (sections = [], brand) => {
    if (!Array.isArray(sections)) {
        return [];
    }

    let referenceCards = sections.find(i => i.__typename == "ContentfulReferenceCardSection")?.referenceCards || []
    referenceCards = referenceCards.filter(i => i.__typename == "ContentfulArticlePage");

    const dirName = '/jsp/contenthub/uk';
    const fileName = 'article';
    const keyParam = 'content';

    // Create list for schema
    // name, url and positions
    return referenceCards.map((item, index) => {
        let name = "";
        let fullUrl = "";
        try {
            const nodeItem = JSON.parse(item?.heroBanner?.contentTitle?.raw);
            name = getTextFromContentArray(nodeItem.content);
        } catch (e) { }

        try {

            if (item.pageIdentifier) {
                const path =
                    process.env.NODE_ENV === 'production'
                        ? generateLink({
                            jspFileName: fileName,
                            jspDirectory: dirName,
                            queryKey: keyParam,
                            queryValue: item.pageIdentifier,
                        })
                        : `/article/${brand}/${item.pageIdentifier}`;

                fullUrl = origin + path;

            }
        } catch (e) { }

        return {
            "@type": "ListItem",
            position: index + 1,
            name,
            url: fullUrl
        }
    }).filter(i => i.name && i.url);

}

export const getHomeSchemaMarkup = ({
    location = {},
    heroBanner = {},
    sections = [],
    brand
}) => {

    const pageUrl = location && location.href ? location.href : '';
    const description = getIntro(heroBanner?.contentTitle?.raw);
    const itemListElement = getItemListElement(sections, brand);

    const schemaObject = {
        "@context": "http://schema.org",
        "@type": "CollectionPage",
        "name": description,
        "description": description,
        "url": pageUrl,
        "mainEntity": {
            "@type": "ItemList",
            "itemListElement": itemListElement
        }
    }

    return schemaObject;
}
