export const getProductData = async (id) => {
  if (!id) return null;

  try {
    const endpoint = process.env.PRODUCT_ENDPOINT;
    const baseUrl = process.env.API_BASE_URL;
    const dynamicContentUrl = `${baseUrl}${endpoint}${id}`;

    const response = await fetch(dynamicContentUrl);
    const json = await response.json();

    return json.response;
  } catch (e) {
    return e;
  }
};
