import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnchorLink as GatsbyAnchorLink } from 'gatsby-plugin-anchor-links';
import standardiseContentfulId from '../../util/standardiseContentfulId';

const props = {
  anchorId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const AnchorLink = ({ anchorId, children }) => {
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, []);

  const href = `${currentUrl}#${standardiseContentfulId(anchorId)}`;

  return <GatsbyAnchorLink to={href}>{children}</GatsbyAnchorLink>;
};

AnchorLink.propTypes = props;

export { AnchorLink };
