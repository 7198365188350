/**
 * Button With Content wrapper wraps the component library button with content to provide rich text for the heading and content
 */
import React from 'react';
import PropTypes from 'prop-types';
import { ButtonWithContent, Button } from 'dw-contentful-components';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { RichText } from '../RichText';
import standardiseContentfulId from '../../util/standardiseContentfulId';
import { AnchorLink } from '../AnchorLink';

const props = {
  brand: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

const headingRichTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (richText) => <strong>{richText}</strong>,
    [MARKS.ITALIC]: (richText) => <i>{richText}</i>,
    [MARKS.UNDERLINE]: (richText) => <u>{richText}</u>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <h2>{children}</h2>,
  },
};

/**
 * Button With Content Wrapper
 * @param {string} brand The brand to style this component for
 * @param {object} data The contentful data for this component
 */
const ButtonWithContentWrapper = ({ brand, data }) => {
  const {
    contentfulId,
    contentTitle,
    contentText,
    button,
    hasShadow,
    card,
    additionalContent,
  } = data;
  const {
    link: { text, url, anchorId },
    buttonType,
  } = button;

  const heading = contentTitle ? (
    <RichText content={contentTitle} options={headingRichTextOptions} />
  ) : null;
  const content = <RichText content={contentText} />;
  const extraContent = additionalContent ? (
    <RichText content={additionalContent} />
  ) : null;

  let shadowStyling;

  if (!card) {
    shadowStyling = hasShadow ? 'bg-shadow' : '';
  } else {
    shadowStyling = hasShadow ? 'bg-shadow' : 'reference-card-background';
  }

  const anchorLink = anchorId && (
    <AnchorLink anchorId={anchorId}>
      <Button brand={brand} label={text} variant={buttonType} />
    </AnchorLink>
  );

  return (
    <div
      data-testid="button-with-content"
      className={`${brand} ${shadowStyling} button-with-content h-100`}
      id={standardiseContentfulId(contentfulId)}
    >
      <ButtonWithContent
        brand={brand}
        heading={heading}
        content={content}
        buttonLabel={text}
        buttonType={buttonType}
        url={url}
        anchorLink={anchorLink}
        card={card}
        subContent={extraContent}
      />
    </div>
  );
};

ButtonWithContentWrapper.propTypes = props;

export { ButtonWithContentWrapper };
