/**
 * ReferenceCardWrapper is wrapper responsible for
 * providing the props to the ReferenceCard component.
 */

import React, { useState, useEffect } from 'react';
import { ReferenceCard } from 'dw-contentful-components';
import PropTypes from 'prop-types';
import { generateLink } from 'dw-contentful-components/src/util/generateLink';
import { getProductData } from '../../util/getProductData/getProductData';

const propTypes = {
  brand: PropTypes.string.isRequired,
  card: PropTypes.shape({
    id: PropTypes.string,
    entryTitle: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.object.isRequired,
    dynamicContentReference: PropTypes.object,
    button: PropTypes.object.isRequired,
  }).isRequired,
};

/**
 * Reference Card Wrapper wraps the reference card component to allow passing down of children 
 * to custom display the vineyard information
 * @param {brand} string The brand, used for generating the right link
 * @param {card} card The card details from contentful
 * @returns 
 */
const ReferenceCardWrapper = ({
  brand,
  card: {
    id,
    entryTitle,
    title,
    content,
    image: {
      accessibilityText: alt,
      asset: { fluid },
    },
    button: { pageIdentifier },
    dynamicContentReference,
  },
}) => {
  const productId = dynamicContentReference?.productId;
  const [productData, setProductData] = useState({});
  const [vineyardUrl, setVineyardUrl] = useState('');

  useEffect(() => {
    const vineyardPath =
      process.env.NODE_ENV === 'production'
        ? generateLink({
            jspFileName: 'vineyard',
            jspDirectory: '/jsp/vineyardpartners/uk',
            queryKey: 'content',
            queryValue: pageIdentifier,
          })
        : `/vineyard/${brand}/${pageIdentifier}`;

    setVineyardUrl(vineyardPath);

    const fetchProductData = async () => {
      const data = await getProductData(productId);

      if (data) {
        const { skus } = data;

        if (skus) {
          setProductData({ data, skusValues: skus[0] });
        }
      }
    };

    if (productId) {
      fetchProductData();
    }
  }, [productId, setProductData]);

  const buttonLinkComponent = (
    <a
      href={vineyardUrl}
      className="btn btn-primary reference-card-button"
      data-testid="discover-button"
    >
      Discover
    </a>
  );

  const { data: cardData, skusValues: cardPrices } = productData || {};
  const { name, colourName, grapeName, countryName } = cardData || {};
  const { salePrice, listPrice, salePricePerBottle, numberOfBottles } =
    cardPrices || {};

  const cardColourName =
    colourName && grapeName ? `${colourName} - ` : colourName;

  const cardGrapeName =
    grapeName && countryName ? `${grapeName} | ` : grapeName;

  return (
    <div className="h-100" data-testid="reference-card-wrapper">
      <ReferenceCard
        id={id}
        title={entryTitle}
        contentTitle={title}
        content={content}
        imageAlt={alt}
        imageFluid={fluid}
        button={buttonLinkComponent}
        productName={name}
      >
        <div className="reference-card-product-data">
          {Object.keys(productData).length > 0 && (
            <>
              <p
                data-testid="reference-card-product-information"
                className="reference-card-product-information"
              >
                {colourName && cardColourName}
                {grapeName && cardGrapeName}
                {countryName}
              </p>
              <p className="reference-card-product-values">
                {salePrice && (
                  <>
                    Partner Price{' '}
                    <span data-testid="sale-price" className="sale-price">
                      £{salePrice.toFixed(2)}
                    </span>
                  </>
                )}{' '}
                {listPrice && (
                  <s data-testid="list-price">£{listPrice.toFixed(2)}</s>
                )}{' '}
                {salePricePerBottle && (
                  <>
                    (£{salePricePerBottle.toFixed(2)}
                    /btl){' '}
                  </>
                )}
              </p>
              <p className="reference-card-number-of-bottles">
                {' '}
                One {numberOfBottles} bottle crate per year{' '}
              </p>
            </>
          )}
        </div>
        {buttonLinkComponent}
      </ReferenceCard>
    </div>
  );
};

ReferenceCardWrapper.propTypes = propTypes;

export { ReferenceCardWrapper };
