/**
 * ArticleReferenceCardWrapper is wrapper responsible for
 * providing the props to the ReferenceCard component.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReferenceCard } from 'dw-contentful-components';
import { BLOCKS } from '@contentful/rich-text-types';
import { RichText } from '../RichText';
import '../../styles/ArticleReferenceCardWrapper.scss';

const {
  generateLink,
} = require('dw-contentful-components/src/util/generateLink');

const propTypes = {
  article: PropTypes.object.isRequired,
  brand: PropTypes.string,
};

const defaultProps = {
  brand: 'law',
};

const ArticleReferenceCardWrapper = ({ article, brand }) => {
  const {
    pageIdentifier,
    postedBy: articleAuthor,
    postedOn: articleDate,
    heroBanner: { contentTitle: cardTitle },
    heroBanner: { image },
    sections,
  } = article || {};

  const {
    accessibilityText: alt,
    asset: { fluid },
  } = image || {};

  const dirName = '/jsp/contenthub/uk';
  const fileName = 'article';
  const keyParam = 'content';

  const [articlePageUrl, setArticlePageUrl] = useState('');

  useEffect(() => {
    const {
      location: { origin },
    } = window;

    let path =
      process.env.NODE_ENV === 'production'
        ? generateLink({
            jspFileName: fileName,
            jspDirectory: dirName,
            queryKey: keyParam,
            queryValue: pageIdentifier,
          })
        : `/article/${brand}/${pageIdentifier}`;

        path = path.replace(origin, "");
    setArticlePageUrl(path);
  }, []);

  const contentTextStyle = 'article-reference-card-content-text';

  const titleRichTextOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <span>{children}</span>,
    },
  };

  const title = <RichText content={cardTitle} options={titleRichTextOptions} />;

  const firstContentBlock = sections.find(
    (section) => section.__typename === 'ContentfulStandardContentBlock',
  );
  const { content: refCardContent } = firstContentBlock || {};
  const content = <RichText content={refCardContent} />;

  const buttonLinkComponent = (
    <a
      href={articlePageUrl}
      className="btn btn-primary reference-card-button"
      data-testid="read-more-button"
    >
      Read More
    </a>
  );

  const postDate = <p>Posted on {articleDate}</p>;
  const postAuthor = <p> by {articleAuthor}</p>;

  return (
    <div className="h-100 " data-testid="article-reference-card-wrapper">
      <ReferenceCard
        contentTitle={title}
        content={content}
        contentTextStyling={contentTextStyle}
        imageAlt={alt}
        imageFluid={fluid}
        url={articlePageUrl}
      >
        <div className="article-reference-card-details">
          {articleDate && postDate}
          {articleAuthor && postAuthor}
        </div>
        {buttonLinkComponent}
      </ReferenceCard>
    </div>
  );
};

ArticleReferenceCardWrapper.propTypes = propTypes;
ArticleReferenceCardWrapper.defaultProps = defaultProps;

export { ArticleReferenceCardWrapper };
